.notification-panel {
  width: 100%;
  background-color: var(--secondary-color);
  border-bottom: 2px solid var(--tertiary-color);
}

.notification-panel-header {
  background: var(--tertiary-color);
  color: var(--primary-color);
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  padding: 12px;
  margin-bottom: 12px;
}

.notification-state-row-container {
  display: flex;
  align-items: center;
  padding: 6px 12px;

  span {
    width: 20px;
  }

  p {
    margin: 0 12px;
    padding: 6px 12px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    color: #fff;
  }
}

.notification-state-row-container:last-child {
  margin-bottom: 12px;
}

@media only screen and (max-width: 710px) {
  .notification-state-row-container {
    flex-direction: column;

    .select-container {
      margin-top: 6px;
    }
  }
}
