.address-asset-display-container {
  display: flex;
  align-items: center;

  img {
    margin-right: 6px;
  }
}

#address-transactions-list {
  margin-top: 12px;
  margin-bottom: 24px;
}
