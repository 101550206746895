.stats-as-columns {
  flex-direction: row;
  display: none !important;
  flex: 1;

  .column {
    flex-direction: column;
    display: flex;
    flex: 1;
    align-items: center;
  }
}

#statistics-card-container {
  .card {
    width: 100%;
    flex: 1 1;
    padding: 0;
  }
}

@media only screen and (max-width: 910px) {
  .stats-as-columns {
    display: flex !important;
  }

  .stats-as-row {
    display: none !important;
  }
}

@media only screen and (max-width: 810px) {
  #statistics-card-container {
    .card {
      max-width: 275px;
      margin: 10px 0;
    }
  }
}

@media only screen and (max-width: 700px) {
  #statistics-card-container {
    .card {
      max-width: 255px;
      margin: 10px 0;
    }
  }
}

@media only screen and (max-width: 575px) {
  #statistics-card-container {
    .card {
      max-width: 200px;
    }
  }
}

@media only screen and (max-width: 450px) {
  #statistics-card-container {
    .card {
      max-width: 165px;
    }
  }
}
