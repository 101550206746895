.secondary-button.active-button {
  background: var(--quaternary-color);
  color: #000033;
}

.primary-button {
  background: var(--tertiary-color);
  padding: 12px;
  min-width: 145px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: #000033;
  text-align: center;
  border-radius: 3px;
  font-family: 'Rajdhani', sans-serif;
}

.secondary-button {
  background: none;
  padding: 12px;
  min-width: 145px;
  font-size: 16px;
  border: 1px solid var(--tertiary-color);
  color: var(--tertiary-color);
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  border-radius: 3px;
  font-family: 'Rajdhani', sans-serif;

  svg {
    margin: 0 6px;
  }
}

.secondary-button:hover,
.secondary-button svg:hover {
  background: var(--quaternary-color);
  color: #000033;
  polyline {
    stroke: #000033;
  }
}

.secondary-button:disabled {
  cursor: default;
  opacity: 0.5;
}

.secondary-button:disabled:hover,
.secondary-button svg:disabled:hover {
  cursor: default;
  opacity: 0.5;
  background: none;
  color: var(--tertiary-color);

  polyline {
    stroke: var(--tertiary-color);
  }
  // background: var(--quaternary-color);
  // color: #000033;
  // polyline {
  //   stroke: #000033;
  // }
}
