#navigation {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}

#nav-bottom-border {
  border-bottom: thin var(--text-color-secondary) solid;
  margin: 0 24px;
}

#burger-menu-icon {
  cursor: pointer;
}

#desktop_navigation_options a {
  margin: 24px;
  font-size: 17px;
  color: var(--text-color);
  text-align: center;
  text-decoration: none;
}

#desktop_navigation_options a {
  &:hover {
    color: var(--tertiary-color);
    text-decoration: underline;
  }
}

#logo {
  display: flex;
  align-items: center;
  margin: 0 0 0 24px;
}

.logo-sub-text {
  display: flex;
  flex-direction: column;
}

#coz-logo-home-link {
  &:hover {
    text-decoration: none !important;
  }
}

.logo-sub-text p {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  color: var(--text-color);
  justify-content: center;
}

.logo-sub-text h3 {
  margin: 0;
  font-size: 24px;
  color: var(--text-color);
  white-space: nowrap;
  overflow: hidden;
}

.logo-sub-text img {
  margin-left: 6px;
}

#logo-spacer {
  border: 1px solid var(--text-color-secondary);
  height: 55px;
  margin-left: 24px;
}

#mobile-coz-logo {
  display: none;
}

#mobile-navigation {
  display: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: static;
  width: 36px;
  height: 30px;
  right: 0;
  top: 0;
  margin: 24px 24px -52px auto;

  button {
    display: none;
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--quinary-color);
  /*opacity: 0.95;*/
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: hidden !important;
  margin-top: -24px;
}

#close-mobile-menu-button {
  font-size: 18px;
  color: var(--text-color);
  text-align: right;
  margin-top: -30px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin: 24px 0;
  font-size: 30px;
  color: var(--text-color);
  text-align: center;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.mobile-navigation-links-container {
  display: flex !important;
  flex-direction: column;

  a {
    text-decoration: none;
  }
}

#desktop-links-and-search-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 12px 24px 12px 12px;

  img {
    margin-left: -32px;
    margin-right: 12px;
  }
}

.logo-sub-text {
  margin-left: 24px;
}

@media only screen and (max-width: 1200px) {
  #navigation {
    height: 112px;
  }
  #desktop-links-and-search-container {
    flex-direction: column;
    margin: 12px 24px 12px 12px;
  }
  #logo-spacer {
    margin-left: 12px;
  }

  .logo-sub-text {
    margin-left: 12px;
  }

  #desktop-links-and-search-container {
    input {
      margin-bottom: 12px;
    }

    img {
      margin-bottom: 12px;
    }
  }
}

@media only screen and (max-width: 810px) {
  #mobile-navigation {
    display: block;
  }
  .bm-burger-button {
    display: block;
  }

  #desktop-links-and-search-container {
    display: none;
  }

  #logo {
    #neo-3-logo {
      width: 75px;
    }
  }

  #navigation {
    height: 75px;
  }

  #nav-bottom-border {
    margin: 0 18px;
  }

  #mobile-coz-logo {
    display: flex;
    align-items: center;
    margin-left: 12px;
    img {
      max-width: 149px;
      max-height: 36px;
    }
  }

  #desktop-coz-logo {
    display: none;
  }

  #logo-spacer {
    border: 1px solid var(--text-color-secondary);
    height: 40px;
    margin-left: 12px;
  }

  .logo-sub-text img {
    margin-left: -6px;
  }

  .mobile-navigation-links-container input {
    margin-top: 24px;

    width: 100%;
  }

  .mobile-navigation-links-container img {
    margin-left: -30px;
    margin-top: 24px;
  }

  .search-input {
    width: 100%;
  }
}
