.panel {
  background-color: var(--secondary-color);
  padding: 6px 16px;
  height: 24px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  border-bottom: solid 2px var(--tertiary-color);

  div {
    display: block;
    word-wrap: anywhere;
    word-break: break-word;
  }

  .panel-link,
  .link,
  a {
    font-size: 14px !important;
  }
}

.panel span {
  margin-right: 12px;
  font-weight: bold;
}

.panels-container {
  display: flex;
  flex-direction: column;
}

.panels-row {
  display: flex;
  flex: 1;
  max-width: 100%;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.panel:nth-child(1) {
  margin: 6px 8px 6px 0;
}

.panel:nth-child(2) {
  margin: 6px 0px 6px 8px;
}

.secondary-panels-column:nth-child(1) {
  margin: 6px 8px 6px 0;
}

.secondary-panels-column:nth-child(2) {
  margin: 6px 0px 6px 8px;
}

.panels-container .panel {
  display: flex;
  min-width: 40%;
  flex: 0.5;
}

.panel-header {
  margin: 24px 0 12px 0;
  padding-bottom: 12px;
  border-bottom: solid 2px var(--text-color-secondary);
}

.panel-header-and-explore-row {
  margin: 24px 0 12px 0;
  padding-bottom: 12px;
  border-bottom: solid 2px var(--text-color-secondary);
  display: flex;
  justify-content: space-between;

  h1 {
    div {
      margin-left: 12px;
    }
  }
}

.secondary-panel {
  min-width: 40% !important;
  flex: 1;
  margin: 12px 0 !important;
  padding: 12px;
  height: auto !important;
  div {
    display: inline-block;
    word-break: break-word;
  }
}

.secondary-panels-row {
  display: flex;
  flex: 1;
  max-width: 100%;
}

.secondary-panels-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 50%;
}

.hidden-panel {
  background: transparent;
  border-bottom: none;
}

@media only screen and (max-width: 810px) {
  .panel:nth-child(1) {
    margin: 0;
  }

  .panel:nth-child(2) {
    margin: 0;
  }

  .secondary-panels-row {
    flex-direction: column;
    width: 100%;

    .secondary-panels-column {
      margin: 6px 0;
      max-width: 100% !important;
    }
  }

  .panels-row {
    flex-direction: column;
    .panel {
      margin: 3px 0;
      max-height: 25px;
    }
  }
}

@media only screen and (max-width: 710px) {
  .panel-header-and-explore-row {
    a {
      font-size: 14px;
    }
    h1 {
      flex-direction: column;
      display: flex;

      .bold-subtitle {
        margin: 0;

        a {
          padding-right: 12px;
          display: block;
        }
      }
    }
  }
}
