.footer {
  flex-shrink: 0;
  border-top: thin solid var(--text-color-secondary);
  margin: 0 24px;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-flex-container {
  flex: 1;
  display: flex;
}

.footer span {
  justify-content: center;
}

#footer-theme-toggle {
  display: flex;
  align-items: center;
  padding: 0.5em;
  margin-right: 1em;
  border-radius: 100px;
  background-color: var(--secondary-color);
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #555;
  box-shadow: 0px 0px 2px 2px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none !important;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}

#footer-theme-toggle span {
  margin-right: 1em;
  font-size: 0.8em;
}

#footer-social-icons {
  justify-content: flex-end;
}

#footer-social-icons img {
  opacity: 0.75;
  cursor: pointer;
  margin: 0 8px;
}

#footer-social-icons img:hover {
  opacity: 1;
}

#footer-social-icons {
  a {
    display: flex;
    align-items: center;
  }
}

.react-toggle--checked .react-toggle-track {
  background-color: #4d4d4d;
}

.react-toggle--checked .react-toggle-thumb {
  border-color: #fafafa;
  background-color: #fafafa;
  opacity: 0.75;
}

@media only screen and (max-width: 810px) {
  .footer {
    flex-direction: column;
    padding: 12px;
  }

  #footer-social-icons img {
    max-width: 25px;
  }

  .footer-flex-container {
    margin: 6px;
  }
}

@media only screen and (min-width: 1464px) {
  .footer {
    align-self: center;
    width: 100%;
    max-width: 1440px;
  }
}
