#call-to-action {
  background-color: var(--secondary-color);
  padding: 12px;
  margin: 12px 12px 24px 12px;
  border-radius: 3px;

  h1 {
    margin-left: 24px !important;
    margin-top: 12px;
  }
}

#call-to-action-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#call-to-action-content p {
  display: flex;
  flex: 0.6;
  margin-left: 24px;
  margin-right: 24px;
  font-size: 17px;
  line-height: 24px;
}

#call-to-action-button-container {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex: 0.5;
  flex-wrap: wrap;
}

#call-to-action-button-container span {
  font-weight: 600;
  min-width: 150px;
  margin: 12px 0;
  text-align: center;
}

#statistics-card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

#landing-page h1 {
  margin-left: 12px;
}

.statistic {
  display: flex;
}

.header-and-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-and-link .link {
  margin-right: 12px;
}

.list-block-height-container {
  color: var(--tertiary-color);
  display: flex;
  align-items: center;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.last-5-blocks-header {
  margin-top: 12px;
}

#lading-page-block-list-container {
  padding: 12px;
  margin-bottom: 24px;
}

.list-block-height-container img {
  margin-right: 12px;
}

@media only screen and (max-width: 1175px) {
  #call-to-action-content p {
    flex: 1;
  }
  #call-to-action-button-container {
    flex: 0.5;
  }
  #call-to-action-button-container {
    flex-direction: column;
    margin-top: -50px;
    padding: 12px;
  }
}

@media only screen and (max-width: 900px) {
  #call-to-action {
    margin-bottom: 24px;
    max-height: 250px;
  }
  #call-to-action-content {
    flex-direction: column;
  }

  #call-to-action-button-container {
    margin-top: 0px;
    padding-bottom: 12px;
    flex-direction: row;
    flex: 1;
    justify-content: center;
  }
}

@media only screen and (max-width: 810px) {
  .list-block-height-container {
    font-size: 12px;
  }
  #landing-page h1 {
    font-size: 24px;
    color: var(--text-color);
  }

  #landing-page a {
    font-size: 15px;
  }

  #call-to-action {
    padding: 12px;
    margin: 0 6px 12px 6px;

    h1 {
      margin-top: 12px;
      margin-right: 12px;
      text-align: center;
    }
  }

  #call-to-action p {
    text-align: center;
    padding: 0;
    margin: 6px 6px 12px 6px;
    max-width: 500px;
    align-self: center;
  }
  #lading-page-block-list-container {
    padding: 12px 6px;
    margin-bottom: 12px;
  }

  .header-and-link .link {
    margin-right: 6px;
  }

  #landing-page h1 {
    margin-left: 6px;
  }

  #statistics-card-container {
    padding: 0px;
  }

  .block-icon {
    display: none;
  }

  #statistics-card-container {
    margin: auto;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 525px) {
  #call-to-action-button-container {
    justify-content: space-around;
    span {
      display: none;
    }
  }
}

@media only screen and (max-width: 400px) {
  .header-and-link a {
    margin-right: 12px;
  }

  #call-to-action h1 {
    margin-top: 6px;
  }

  #call-to-action-button-container {
    padding: 0;
    margin-bottom: 6px;
    justify-content: space-around;
  }

  #call-to-action {
    max-height: 350px;
  }
}
