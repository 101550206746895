.expanding-panel-header {
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  height: 40px;
  align-items: center;

  button {
    min-height: 40px !important;
    min-width: 40px !important;

    svg {
    }
  }
}

.expanding-panel-title {
  margin: 12px;
  font-weight: 600;
}
