.content {
  flex: 1 0 auto;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.router-content {
  padding: 24px 110px;
  max-width: 1184px;
  margin: auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

@media only screen and (min-width: 1441px) {
  .content {
    margin: auto;
    max-width: 1440px !important ;
    min-width: 1440px !important;
  }

  #root {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1400px) {
  .wrapper {
    padding: 12px;
  }

  .router-content {
    padding: 24px 12px;
  }
}
