* {
  font-family: 'Rajdhani', sans-serif;
}

html {
  overflow: auto;
}

html.dark-mode {
  background-color: var(--primary-color);
}

html,
body {
  min-height: 100vh;
}

body {
  background-color: var(--primary-color);
  color: var(--text-color-secondary);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rajdhani', sans-serif;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #ffffff;
  --secondary-color: #f6f6f6;
  --tertiary-color: #02e49b;
  --quaternary-color: #00e599;
  --quinary-color: #efefef;
  --text-color: #003;
  --text-color-secondary: #003;
  --overlay-color: rgba(0, 0, 51, 0.26);
  --overlay-color-secondary: #f6f6f6;
}

:root .dark-mode {
  --primary-color: #000033;
  --secondary-color: rgba(255, 255, 255, 0.08);
  --tertiary-color: #02e49b;
  --quaternary-color: #00e599;
  --quinary-color: #000033;
  --text-color: #fff;
  --text-color-secondary: #f9fafa;
  --overlay-color: rgba(0, 0, 51, 0.8);
  --overlay-color-secondary: #1f1f4b;
}

a,
.link,
.panel-link {
  color: var(--tertiary-color);
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  word-wrap: anywhere;
}

a:hover,
.link:hover,
.panel-link:hover {
  text-decoration: underline;
}

h1 {
  margin: 0;
  font-size: 30px;
  color: var(--text-color);
}

a:focus,
button:focus,
nav:focus,
div:focus {
  outline: 0;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.list-block-height-container img,
#footer-social-icons a,
.carousel-button-container img,
#desktop-coz-logo img,
#mobile-coz-logo img,
#chevron-right-transfer,
#burger-menu-icon,
.expanding-panel-container .explore-button img {
  filter: invert(100%);
}

.dark-mode .list-block-height-container img,
.dark-mode #footer-social-icons a,
.dark-mode .carousel-button-container img,
.dark-mode #desktop-coz-logo img,
.dark-mode #mobile-coz-logo img,
.dark-mode #chevron-right-transfer,
.dark-mode #burger-menu-icon,
.dark-mode .expanding-panel-container .explore-button img {
  filter: invert(0%);
}

@media screen and (min-width: 1400px) and (max-width: 1799px) {
  .carousel-wrapper {
    overflow-x: visible !important;
  }

  .content {
    margin: auto;
    width: 1440px;
  }

  #root {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 1800px) {
  .carousel-wrapper {
    width: 1825px !important;
    margin-left: -325px;
    overflow: hidden;
  }
  .carousel-item-wrapper {
    width: 15.7% !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
