.card {
  background-color: var(--secondary-color);
  border-top: 3px transparent solid;
  max-width: 276px;
  max-height: 95px !important;
  min-width: 150px;
  min-height: 95px;
  margin: 10px;
  border-bottom: 3px var(--tertiary-color) solid;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 48px;
  border-radius: 3px;
}

.card h2 {
  margin: 0;
  font-size: 30px;
  color: var(--text-color-secondary);
  text-align: center;
  margin-top: -5px;
}

.card p {
  margin: 0;
  font-size: 17px;
  color: var(--text-color-secondary);
  text-align: center;
  line-height: 24px;
}

@media only screen and (max-width: 700px) {
  .card {
    padding: 12px 22px;
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .card {
    max-width: 167px;
    min-width: 100px;
  }
  .card p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 475px) {
  .card {
    padding: 12px 15px;
    width: 110px;
  }
}
