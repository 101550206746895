.inner-select-container {
  min-width: 125px;
  margin-right: 12px;
  border-right: solid 1px rgba(249, 250, 250, 0.3);
}

.select-container {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 3px;
  padding-right: 12px;
}

.inner-select-container .react-select__control,
.inner-select-container .react-select__menu,
.inner-select-container .react-select__menu,
.select-computed-value {
  background-color: var(--primary-color);
}

.react-select__indicator {
  padding: 4px 8px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: var(--text-color-secondary);
  -webkit-box-shadow: 0 0 0px 1000px '#000033' inset;
  transition: background-color 5000s ease-in-out 0s;
}

@media only screen and (max-width: 710px) {
  .select-container {
    flex-direction: column;
    padding-right: 0;
    background-color: transparent;

    .inner-select-container {
      margin-right: 0;
      margin-bottom: 6px;
      border-right: none;
      background-color: var(--secondary-color);
      border-radius: 3px;
    }

    .select-computed-value {
      word-wrap: anywhere;
      background-color: var(--secondary-color);
      padding: 6px 12px;
      border-radius: 3px;
    }
  }
}
