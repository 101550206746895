.feature-detailed-description-container {
  display: flex;
  flex-direction: column;
}

.ReactModal__Content {
  min-width: 400px;
}

.blur-card {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(2.5px);
  background: rgba(255, 255, 255, 0.08);
}

.feature-card {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  margin: 10px;
  padding: 24px;
  border-radius: 3px;
  width: 100%;
  text-align: center;
  min-width: 228px;
  max-width: 228px;
  cursor: default;

  h1 {
    margin: 0 !important;
  }

  p {
    text-align: center;
    padding-bottom: 12px;
    font-size: 17px;
    color: var(--text-color);
    text-align: center;
    line-height: 24px;
    margin: 0;
  }

  button {
    width: 129px;
    height: 44px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 6px;
  }

  img {
    max-width: 75px;
    margin: auto;
  }
}

.feature-links-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 24px;
  margin: auto;

  a {
    padding: 12px;
  }
}

.feature-modal-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  height: 100%;

  p {
    font-size: 17px;
    color: var(--text-color);
    text-align: center;
    line-height: 24px;
  }

  .feature-modal-icon {
    max-width: 75px;
    margin: auto;
  }

  #close-modal-button {
    cursor: pointer;
    align-self: flex-end;
  }
}

.feature-card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 24px;
}

.feature-card-title-container {
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 12px;
  }
}
@media only screen and (max-width: 1400px) {
  .carousel-wrapper {
    overflow-x: hidden !important;
  }
}

@media only screen and (max-width: 810px) {
  #feature-card-container {
    padding: 0;
  }
}

@media only screen and (max-width: 500px) {
  .ReactModal__Content {
    min-width: 300px;
  }
}
