.search-input {
  background: var(--secondary-color);
  border-radius: 3px;
  border: none;
  width: 325px;
  height: 35px;
  text-align: left;
  padding: 6px 12px;
  padding-right: 40px;
  font-size: 17px;
  color: var(--text-color);
}

.search-input::placeholder {
  font-size: 17px;
  color: var(--text-color);
  text-align: left;
}

#search-input-container {
  display: flex;
  align-items: center;

  .search-input-icon {
    cursor: pointer;
  }

  input {
    border: 1px solid transparent;
  }

  input:focus {
    border: 1px solid #00e599;
    border-radius: 3px;
    outline: none;
  }
}

.is-searching {
  color: transparent !important;
}

.search-error {
  outline: red auto 5px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .search-input {
    input {
      font-size: 16px;
    }
  }
}
