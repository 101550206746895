.transfer-container {
  background: var(--secondary-color);
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 3px;
  display: flex;
}

.transfer-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

.asset-transfer-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
}

.transfer-amount-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  img {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 810px) {
  .transfer-container {
    flex-direction: column;
  }

  #chevron-right-transfer {
    display: none;
  }

  .transfer-column:last-child {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 400px) {
  .transfer-container {
    a {
      font-size: 14px;
    }
  }
}
