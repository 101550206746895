.carousel-news-article-container {
  padding: 0;
  margin: 0 12px;
  min-height: 350px;
  display: block;

  a {
    text-decoration: none;
  }

  .news-article {
    padding: 0px;
    border-bottom: solid 3px transparent;
    min-width: 276px;
    cursor: pointer;

    &:hover {
      border-bottom: solid 3px #02e49b;
    }

    .feature-card-title-container {
      text-align: left;

      span {
        padding: 17px 17px 0 17px;
        font-weight: 400;
        font-size: 14px;
        color: var(--text-color);
        line-height: 16px;
      }

      img {
        width: 100%;
        max-width: 100%;
        margin: 0;
        border-radius: 3px 3px 0 0;
      }

      h1 {
        padding: 17px 17px 24px 17px;
        font-size: 17px;
        color: var(--text-color);

        font-weight: 600;
      }
    }
  }
}

#news-article-carousel-container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.carousel-item-wrapper {
  display: flex;
  justify-content: center;
}

.carousel-button-and-header-container {
  display: flex;
  justify-content: space-between;
  cursor: default;

  h1 {
    margin-left: 0 !important;
  }

  .carousel-button-container {
    display: flex;
    justify-content: flex-end;

    img {
      cursor: pointer;
      opacity: 0.75;
    }

    img:hover {
      opacity: 1;
    }

    img:first-child {
      margin-right: 12px;
    }
    img:last-child {
      margin-left: 12px;
    }
  }
}

@media only screen and (max-width: 810px) {
  .carousel-news-article-container {
    margin: 0 6px;
  }

  .carousel-button-container {
    img {
      max-width: 24px;
    }
  }

  #news-article-carousel-container {
    margin-top: 12px;
    margin-bottom: 24px;
  }
}
