#block-transactions-list {
  margin-top: 48px;
}

.bold-subtitle {
  margin: 6px 0 0;
  font-size: 17px;
  font-weight: bold;
  color: var(--text-color);
  display: inline-block;
  word-break: break-word;
}

.block-time {
  margin-bottom: 12px;
}
